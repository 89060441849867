<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container-fluid">
          <div class="row">
            <div
              class="col-4 d-lg-flex d-none h-100 my-auto ms-auto pe-0 position-relative top-0 end-0 text-center justify-content-center flex-column">
              <div class="position-relative h-100 m-3 ps-7 border-radius-lg d-flex flex-column justify-content-center">
                <div class="position-relative">
                  <img class="w-100 position-relative z-index-2" src="@/assets/img/pancard-details.png" />
                </div>
              </div>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-7 d-flex flex-column me-xl-9 mx-md-auto mt-4 px-xl-6">
              <div class="card shadow-lg">
                <div class="card-header mx-4 mx-xl-5 pb-0 text-left">
                  <h4>Enter your PAN details</h4>
                </div>
                <div class="card-body pb-3 mx-4 px-2 px-xl-5">
                  <Form role="form" @submit="onSubmit">
                    <div class="mb-5 mb-sm-4">
                      <label class="ones-label-size">PAN</label>
                      <soft-input id="panumber" class="pancard-input" v-model="panNumber" :rules="{
                    required: true,
                    regex:
                      /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                  }" type="text" placeholder="BUNBEXXXXA" aria-label="pan" name="PAN number" maxlength="10" />
                    </div>
                    <div class="mb-5 mb-sm-4">
                      <label class="ones-label-size">Date of Birth (as per your PAN)</label>
                      <soft-calendar v-model="dob" id="dob" rules="required:true|pastDate" placeholder="DD-MM-YYYY"
                        name="Date of Birth" />
                    </div>
                    <soft-checkbox id="flexCheckDefault" rules="is_not:on" name="terms" checked="false" v-model="term"
                      class="font-weight mt-2 mb-4">
                      <template v-slot:label>
                        <label class=" mt-2 font-weight-lighter text-justify">
                          By continuing, I agree to give my consent to download my KYC Records from the Central KYC
                          Registry (CKYCR) only for the purpose of verification of my identity and address from
                          the database of
                          CKYCR Registry.
                          <a href="#" data-bs-toggle="modal" data-bs-target="#modal-default"
                            class="text-info font-weight-bolder me-1"><u> Terms & Conditions </u></a></label>
                      </template>
                    </soft-checkbox>
                    <div class="text-center"><small class="mt-4 text-danger text-xs" v-if="!isSuccess">{{ error
                        }}</small>
                    </div>
                    <div class="text-center">
                      <soft-button color="primary" type="submit" full-width class="mt-3 mb-2">Proceed</soft-button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div>
      <div class="modal fade" id="modal-default" tabindex="-1" role="dialog" aria-labelledby="modal-default"
        aria-hidden="true">
        <div class="modal-dialog w-100 h-100 modal-dialog-centered" role="document">
          <div class="modal-content" style="max-height: 70%; max-width: 90%">
            <div class="text-center my-3">
              <h6 class="modal-title text-center" id="modal-title-default">
                Terms & Condition
              </h6>
            </div>
            <div class="overflow-auto container mw-100 mh-100">
              <p class="text-justify">
                I give my consent to download my KYC Records from the Central KYC Registry (CKYCR) only for the purpose of verification of my identity and address from the
                database of
                CKYCR Registry. I understand that my KYC Record includes my Personal information, such as my
                name, address, date of birth, PAN number etc.
              </p>
            </div>
            <div class="text-end">
              <button type="button" class="btn btn-link ml-auto" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SoftInput from "@/views/common/components/SoftInput.vue";
import SoftButton from "@/views/common/components/SoftButton.vue";
import SoftCalendar from "@/components/SoftCalendar.vue";
import SoftCheckbox from "@/views/common/components/SoftCheckbox.vue";
import { mapMutations } from "vuex";
import { Form } from "vee-validate";
export default {
  name: "PancardVerification",
  components: {
    SoftInput,
    SoftButton,
    SoftCalendar,
    SoftCheckbox,
    Form,
  },
  data() {
    return {
      panNumber: '',
      dob: '',
      consent: {
        ip: '',
        language: '',
        browser: '',
        os: '',
        clientTimeStamp: '',
        location: 'India',
        screenSize: '',
      },
      isSuccess: true,
      error: "",
      email: "",
      term: "on",
    };
  },
  mounted() {
    const languageCode = navigator.language || navigator.userLanguage;
    this.consent.language = languageCode;
    this.consent.clientTimeStamp = this.getOffsetDateTime();
    this.consent.screenSize = `${window.screen.width}*${window.screen.height}`;
    this.$store.state.showFooter = false;
    this.$store.state.showSidenav = false;
    this.consent.browser = this.detectBrowser();
    this.consent.os = this.detectOS();
    fetch('https://jsonip.com/')
      .then(response => response.json())
      .then(response => {
        this.consent.ip = response.ip;
      });
    var userPayload = JSON.parse(sessionStorage.getItem('userPayload'));
    this.email = userPayload.email;
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  watch: {
    panNumber(newVal) {
      this.panNumber = newVal.toUpperCase();
    }
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    getOffsetDateTime() {
      const now = new Date();
      const isoString = now.toISOString();
      const withoutMilliseconds = isoString.substring(0, isoString.length - 5) + "Z";

      return withoutMilliseconds;
    },
    detectBrowser() {
      const userAgent = navigator.userAgent;
      if (userAgent.match(/Edg/i)) {
        return "Edge";
      } else if (userAgent.match(/OPR/i) || userAgent.match(/Opera/i)) {
        return "Opera";
      } else if (userAgent.match(/Chrome/i)) {
        return "Chrome";
      } else if (userAgent.match(/Firefox/i)) {
        return "Firefox";
      } else if (userAgent.match(/Safari/i) && !userAgent.match(/Chrome/i) && !userAgent.match(/OPR/i)) {
        return "Safari";
      } else {
        return "Unknown";
      }
    },
    detectOS() {
      const platform = navigator.platform;
      const userAgent = navigator.userAgent;
      if (platform.startsWith('Win')) {
        return 'Windows';
      } else if (/Mac/i.test(platform)) {
        return 'macOS';
      } else if (/Android/i.test(userAgent)) {
        return 'Android';
      } else if (/Linux/i.test(platform)) {
        return 'Linux';
      } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        return 'iOS';
      } else {
        return 'Unknown';
      }
    },
    onSubmit() {
      let data = {
        "pan": this.panNumber,
        "email": this.email,
        "dateOfBirth": this.dob,
        "consent": this.consent
      };
      var self = this;
      this.$store
        .dispatch("userStore/getCkyc", data, { root: true })
        .then(response => {
          let name = response.server.data.fullName;
          sessionStorage.setItem("name", name);
          self.isSuccess = true;
          self.$router.push({ name: 'Additional Info', params: { userName: name } });
        })
        .catch(function (error) {
          console.log(error);
          self.error = "The provided PAN details are not KYC compliant. Please complete your KYC verification to proceed.";
          self.isSuccess = false;
        });
    },
  },

};
</script>